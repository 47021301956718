<template>
  <section>
    <template v-for="(d, dind) in items">
      <div class="stud-history-teaser"
           v-if="d.material"
           :key="'hisdata-'+dind">
        <b-row class="align-items-center">
          <b-col lg cols="12">{{ d.material.title }}</b-col>
          <b-col lg="auto" cols="12">
            <div class="text-muted date">{{ d.start | moment('HH:mm, DD MMM YYYY') }}</div>
          </b-col>
          <b-col lg="3" cols="12">
            <div class="text-muted date pl-lg-2 pl-0">
              <div>
                {{ $t('homework') }}:
              </div>
              <div>
                <span v-if="d.pivot.homework_done">{{ $t('done') }}</span>
                <span class="text-danger" v-else>{{ $t('not_done') }}</span>
              </div>
              <div class="text-success" v-if="d.pivot.homework_reviewed">{{ $t('reviewed') }}</div>
              <router-link v-if="d.pivot.homework_done && !d.pivot.homework_reviewed"
                           class="btn-themed btn-white btn-sm"
                           :to="{ name: 'Lesson', params: { id: d.id, code: d.code, homework: 'homework', studentid: student.id }}">
                {{ $t('check') }}
              </router-link>
            </div>
          </b-col>
          <b-col lg="auto" cols="12">
            <star-rating :show-rating="false"
                         :padding="5"
                         active-color="#B1B7C7"
                         border-color="#B1B7C7"
                         :border-width="1"
                         inactive-color="#fff"
                         :star-size="24"
                         :read-only="true"
                         v-model="d.pivot.grade">
            </star-rating>
          </b-col>
        </b-row>
      </div>
    </template>
    <div v-if="items.length == 0" class="pt-3">
      {{ $t('no_data_available') }}
    </div>
    <div v-if="hasMore && !loading" class="text-center mt-5">
      <a class="btn btn-themed btn-themed-squared btn-themed-outline btn-sm px-5"
         @click="loadMore">
      {{ $t('more') }}
      </a>
    </div>
  </section>
</template>

<script>
import StarRating from 'vue-star-rating'
import { StudentsService } from "../../../../../services/api.service"

export default {
  components: {
    StarRating
  },
  data() {
    return {
      page: 1,
      items: [],
      loading: false
    }
  },
  props: {
    student: {}
  },
  computed: {
    history() {
      return this.student.history
    },
    hasMore() {
      return this.page < this.history.last_page
    }
  },
  mounted() {
    this.items = JSON.parse(JSON.stringify(this.history.data))
  },
  methods: {
    async loadMore() {
      this.page++
      this.loading = true
      const res = await StudentsService.getHistory(this.student.id, this.page)
      this.items = this.items.concat(res.data.data.data)
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.stud-history-teaser {
  padding: 24px 0;
  border-bottom: 1px solid $border-gray;
  font-size: 16px;
  .date {
    font-size: 14px;
  }
}
@media(max-width: 768px) {
  .stud-history-teaser {
    font-size: 14px;
  }
}
</style>
